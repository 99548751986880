import React, { useEffect, useState } from 'react';
import { Blocks } from './Blocks';
import Sidebar from './Sidebar';
import '../style/global.css';



const Home = () => {
    
    return (
        <div className='container'>
            <link href="https://api.fontshare.com/v2/css?f[]=satoshi@900,500,400&display=swap" rel="stylesheet"></link>
            <Sidebar />
            <div className='main'>
                <Blocks />
            </div>
        </div>
    );
};

export default Home;
