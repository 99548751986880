import React from "react";
import Blocks from "../Blocks";
import OneBlock from "../OneBlock";

const FirstBlock = () => {
    return (
        <div className="welcome">
            <h2>Curate your first Block</h2>
            <p>As you might guess from the name, blocks are the core elements of your newsletter. These blocks contain the articles and resources you want to share with your readers. Now, it's time to add your first block.</p>
            <h3>Let's add our first block!</h3>
            <p>1. Search for a news article in the topic of your choosing
            <br></br>2. Open up the Newsblocks chrome extension, select "News" and click Add then come back here</p>
            <OneBlock />
        </div>
    );
};

export default FirstBlock;