/* similar to Settings.js but only the content inside main*/

import React, { useState, useEffect } from 'react';
import api from '../services/api';
import '../style/global.css';

import { useAuth } from '@clerk/clerk-react';
import { useWorkspace } from './WorkspaceContext';

const AddBlockUnwrapped = () => {
    const [url, setUrl] = useState('');
    const [type, setType] = useState('bites');
    const [content, setContent] = useState('');
    const [tweetId, setTweetId] = useState('');
    const [label, setLabel] = useState('');

    const { getToken } = useAuth();
    const [token, setToken] = useState('');
    
    const {workspaceId} = useWorkspace();

    const fetchToken = async () => {
        const fetchedToken = await getToken();
        setToken(fetchedToken);
    };
    
    const handleTweetIdChange = (e) => {
        let input = e.target.value;
        // Check if the input is a Twitter URL
        const twitterUrlRegex = /^https:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)$/;
        const match = input.match(twitterUrlRegex);
        if (match && match[3]) {
            // If it is a Twitter URL, set tweetId to the ID part of the URL
            setTweetId(match[3]);
        } else {
            // If not, set tweetId to the input value
            setTweetId(input);
        }
    };

    const addBlock = async () => {
        await fetchToken();  // fetch a new token
        if (type == "thought") {
            api.post('/block', { content, type, workspaceId }, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                window.notify('Block added');
                setContent('');
            })
            .catch(error => {
                window.notifyRed('Error adding block : '+error.response.data.error);
            });
            return;
        }else if (type == "twitter") {
            api.post('/block', { tweetId, type, workspaceId, label }, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                window.notify('Block added');
                setTweetId('');
                setLabel('');
            })
            .catch(error => {
                window.notifyRed('Error adding block : '+error.response.data.error);
            });
            return;
        }
        api.post('/block', { url, type, workspaceId }, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                window.notify('Block added');
                setUrl('');
            })
            .catch(error => {
                window.notifyRed('Error adding block : '+error.response.data.error);
            });
    };

    useEffect(() => {
        fetchToken();
    }, [getToken]);
            
    return (
        <div className='popup-inside'>
        <h1>Manually add block</h1>
        <p className='description'>Consider downloading the <a target="_blank" rel="noreferrer" href='https://chrome.google.com/webstore/detail/newsblok/npionmlffhmmjkiidhdmigfkfapbdjbc'>Extension</a> for a more streamlined experience.</p>    
        <div className='add-block'>
            <div className='setting-tab'>
                <div className='setting-tab-title'>Block Type</div>
                <div className='setting-tab-content'>
                    <select id='type' name='type' value={type} onChange={e => setType(e.target.value)}>
                        <option value='bites'>Bites</option>
                        <option value='news'>News</option>
                        <option value='resource'>Resource</option>
                        <option value='thought'>Custom Thought</option>
                        <option value='twitter'>Tweet</option>
                    </select>
                </div>
            </div>
            {type != "thought" && type != "twitter" &&
            <div className='setting-tab'>
                <div className='setting-tab-title'>Block URL</div>
                <div className='setting-tab-content'>
                    <input className='block-urls' type='text' placeholder='https://www.example.com/...' value={url} onChange={e => setUrl(e.target.value)} />
                </div>
            </div>}
            {type == "thought" &&
            <div className='setting-tab'>
                <div className='setting-tab-title'>Content</div>
                <div className='setting-tab-content'>
                    <textarea className='block-urls' type='text' placeholder='Write your thought here...' value={content} onChange={e => setContent(e.target.value)} />
                </div>
            </div>}
            {type == "twitter" && <>
            <div className='setting-tab'>
                <div className='setting-tab-title'>Tweet ID</div>
                <div className='setting-tab-content'>
                    <input className='block-urls' type='text' placeholder='Paste the tweet ID or URL here...' value={tweetId} onChange={handleTweetIdChange} />
                </div>
            </div>
            <div className='setting-tab'>
                <div className='setting-tab-title'>Label</div>
                <div className='setting-tab-content'>
                    <input className='block-urls' type='text' placeholder='Put a name/title/label to identify the tweet' value={label} onChange={e => setLabel(e.target.value)} />
                </div>
            </div></>
            }
            
            <div className='setting-submit-container'>
                <button className='generate yellow' onClick={addBlock}>Add Block</button>
            </div>
        </div> 
        </div>
    );
}

export default AddBlockUnwrapped;
