import React, { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserButton } from "@clerk/clerk-react";
import UsageBar from './UsageBar';
import api from '../services/api';
import { useAuth } from '@clerk/clerk-react';
import Topbar from './Topbar';

const Sidebar = () => {
    const location = useLocation();
    const { getToken } = useAuth();
    const [subscriptionType, setSubscriptionType] = useState('Free Trial');
    const [isAdmin, setIsAdmin] = useState(false);
    const [menuItems, setMenuItems] = useState([
        {path: "/", icon: "/block.svg", alt: "blocks", name: "Blocks"},
        {path: "/newsletter", icon: "/newsletter.svg", alt: "newsletter", name: "Newsletters"},
        {path: "/settings", icon: "/settings.svg", alt: "settings", name: "AI Settings"},
        {path: "/tutorial", icon: "/tutorial.svg", alt: "tutorial", name: "Tutorial"},
        {path: "/workspaces", icon: "/workspaces.svg", alt: "workspaces", name: "Workspaces"},
        {path: "/newsfeed", icon: "/rss.svg", alt: "newsfeed", name: "Newsfeeds"},
        {path: "/billing", icon: "/billing.svg", alt: "billing", name: "Billing"}
    ]);


    useEffect(() => {
        const checkAdminStatus = async () => {
        const token = await getToken();
        api.get('/usage/isadmin', { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setIsAdmin(response.data.isAdmin);
                if(response.data.isAdmin){
                    setMenuItems([
                        {path: "/", icon: "/block.svg", alt: "blocks", name: "Blocks"},
                        {path: "/newsletter", icon: "/newsletter.svg", alt: "newsletter", name: "Newsletters"},
                        {path: "/settings", icon: "/settings.svg", alt: "settings", name: "AI Settings"},
                        {path: "/tutorial", icon: "/tutorial.svg", alt: "tutorial", name: "Tutorial"},
                        {path: "/workspaces", icon: "/workspaces.svg", alt: "workspaces", name: "Workspaces"},
                        {path: "/newsfeed", icon: "/rss.svg", alt: "newsfeed", name: "Newsfeeds"},
                        {path: "/billing", icon: "/billing.svg", alt: "billing", name: "Billing"},
                        {path: "/admin", icon: "/admin.svg", alt: "admin", name: "Admin"}]);
                }
            })
            .catch(error => {
                console.error(error);
                setIsAdmin(false);
            });
        };
        checkAdminStatus();
    }, [getToken]);

    

    useEffect(() => {
        window.createLemonSqueezy();
        console.log(window.LemonSqueezy);
        window.LemonSqueezy.Setup({
            eventHandler: (event) => {
                console.log(event);
            }
        })
    }, []);

    useEffect(() => {
        const fetchSubscriptionType = async () => {
            var token = await getToken();
            api.get('/usage/subscription-type', { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    setSubscriptionType(response.data.subscriptionType);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        fetchSubscriptionType();
    }, [getToken]);
    /*
    const upgradeAccount = async () => {
        var token = await getToken();
        console.log(token);
        api.get('/usage/create-checkout', { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                //const url = response.data.data.attributes.url;
                //window.LemonSqueezy.Url.Open(url);
                const url = response.data.url;
                window.open(url);
            })
            .catch(error => {
                console.log(error);
            });
    };*/


    return (<>
        <Topbar />
        <div className='sidebar'>
            <img id='logo' src='/logo.png' alt='logo' />
            <div className='menu'>
                {menuItems.map(item => (
                    <a key={item.path} href={item.path} className={location.pathname === item.path ? 'active' : ''}>
                        <img className='icon' src={item.icon} alt={item.alt} />
                        {item.name}
                    </a>
                ))}
            </div>
            
            <p className='feedback-notice'><a target="_blank" rel="noreferrer" className='simplelink' href='https://chrome.google.com/webstore/detail/newsblok/npionmlffhmmjkiidhdmigfkfapbdjbc'>Extension download</a></p>
            <p className='feedback-notice'><a target="_blank" rel="noreferrer" className='simplelink' href='https://notionforms.io/forms/feedbacksbugs-kcdz6y'>Feedbacks/Bugs</a></p>
            <div className='usagetitlecontainer'>
            <p className='smalltitle'>Usage Limit</p>
            {subscriptionType == 'Free Trial' && <a href="/pricing" class="smallbutton">Upgrade</a>}
            </div>
            <UsageBar/>
            <div className='user-button-container'>
                <UserButton className="sidebar-logout"/>
                <span>My Account <br></br>{subscriptionType}</span>
            </div>
        </div>
        </>
    );
}

export default Sidebar;
