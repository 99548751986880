import React, { useState, useEffect } from 'react';
import api from '../services/api';

import { useAuth } from '@clerk/clerk-react';
import { Block, AdminBlock } from './Block';
import AddBlockUnwrapped from './AddBlockUnwrapped';
import { useParams } from 'react-router-dom';
import { useWorkspace } from './WorkspaceContext';
import SetOrderPopup from './SetOrderPopup';

const Blocks = () => {
    const [showOverlay, setShowOverlay] = useState("hide");

    const [blocks, setBlocks] = React.useState([]);
    const [activeTab, setActiveTab] = React.useState('live');
    const [refresh, setRefresh] = React.useState(false);
    const { getToken } = useAuth();
    const [token, setToken] = useState('');

    const [search, setSearch] = useState('');
    const [dateFilter, setDateFilter] = useState('all');

    const [order, setOrder] = useState([]);

    const {workspaceId} = useWorkspace();

    const fetchToken = async () => {
        const fetchedToken = await getToken();
        setToken(fetchedToken);
        console.log(fetchedToken);
    };

    useEffect(() => {
        fetchToken();
    }, [getToken, refresh]); // getToken is a dependency for this effect

    const triggerRefresh = async () => {
        await fetchToken();  // fetch a new token
        setRefresh(!refresh);
    }

    const filterBlocks = (status) => {
        if (status === 'all') {
            return blocks;
        } else if (status === 'live') {
            return blocks.filter(block => block.status === 'live' || block.status === 'error');
        }
        return blocks.filter(block => block.status === status);
    }
    
    const searchFilter = (blocks) => {
        if (!search) return blocks; // if there's no search term, return the full list
    
        // Assuming you're matching the search term against the block's title
        // Adjust if you're matching against other/different block properties
        return blocks.filter(block =>
            block.title.toLowerCase().includes(search.toLowerCase())
        );
    }

    const dateRangeFilter = (blocks) => {
        if (dateFilter === 'all') return blocks; // If 'all' is selected, return the full list.
    
        const endDate = new Date();
        let startDate = new Date();
    
        switch (dateFilter) {
            case 'yesterday':
                startDate.setDate(startDate.getDate() - 1);
                break;
            case 'week':
                startDate.setDate(startDate.getDate() - 7);
                break;
            case 'month':
                startDate.setMonth(startDate.getMonth() - 1);
                break;
            case 'ninety':
                startDate.setMonth(startDate.getMonth() - 3);
                break;
            default:
                break;
        }
    
        // Filter blocks based on whether their date is within the range.
        return blocks.filter(block => {
            const blockDate = new Date(block.createdAt); // Assuming the date is stored in a 'date' property.
            return blockDate >= startDate && blockDate <= endDate;
        });
    };
    

    const countBlocks = (status) => {
        return blocks.filter(block => block.status === status).length;
    }



    const tabActions = {
        'live': ['delete', 'archive'],
        'archive': ['activate', 'delete'],
        'published': ['activate', 'archive', 'delete']
    }

    React.useEffect(() => {
        // fetch a new token
        api.get('/block?workspaceId=' + workspaceId, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
            const fetchedBlocks = response.data.blocks.filter(block => block.status === 'live');
            
            setBlocks(response.data.blocks);
    
            // Extract IDs from the fetched blocks
            const fetchedBlockIds = fetchedBlocks.map(block => block._id);
    
            // Use the functional form of setOrder to get the latest state
            setOrder(prevOrder => {
                // Add existing: Filter out the IDs that are already in the order
                const newBlockIds = fetchedBlockIds.filter(blockId => !prevOrder.includes(blockId));
                
                // Remove deleted: Retain block IDs that are in both the order and fetchedBlockIds
                const retainedOrder = prevOrder.filter(blockId => fetchedBlockIds.includes(blockId));
    
                // Combine the retainedOrder and newBlockIds to get the updated order
                return [...retainedOrder, ...newBlockIds];
            });
            
        })
        .catch(error => console.log(error));
    
    }, [token, refresh]);
    
    
    
    
    
    

    //refresh every 5 seconds
    React.useEffect(() => {
        const interval = setInterval(() => {
            setRefresh(!refresh);
        }, 5000);
        return () => clearInterval(interval);
    }, [refresh]);

    return (
        <div>
            {showOverlay == "block" && <div className='overlay' onClick={() => setShowOverlay("hide")}>
                <div onClick={(e) => e.stopPropagation()}>
                    <AddBlockUnwrapped />
                    </div>
                </div>}
            {showOverlay == "order" && <div className='overlay' onClick={() => setShowOverlay("hide")}>
            <div onClick={(e) => e.stopPropagation()}>
                    <SetOrderPopup onOrderSet={(newOrder) => setOrder(newOrder)} initialOrder={order} closePopup={() => setShowOverlay("hide")}/>
                </div>
            </div>}
            <h1>Blocks</h1>
            
            <p className='description'>
            Curate blocks that will be used as building blocks for your newsletter.</p>
            <div className='searchbox'>
                {/* search input */}
                <img src='/search.svg' className="searchicon" />
                <input type="text" placeholder="Search blocks" className="search" value={search} onChange={(e) => setSearch(e.target.value)} />
                {/* date filters */}
                <select className="datefilter" value={dateFilter} onChange={(e) => setDateFilter(e.target.value)}>
                    <option value="all">All time</option>
                    <option value="yesterday">Last 24 hours</option>
                    <option value="week">Last 7 days</option>
                    <option value="month">Last 30 days</option>
                    <option value="ninety">Last 90 days</option>
                </select>
                
            </div>
            <div className='blockshead'>
                <div className='tabs'>
                    <div id='active' className={activeTab === 'live' ? 'tab active' : 'tab'} onClick={() => setActiveTab('live')}>
                        Live ({countBlocks('live')})
                    </div>
                    <div id='archive' className={activeTab === 'archive' ? 'tab active' : 'tab'} onClick={() => setActiveTab('archive')}>
                        Archive ({countBlocks('archive')})
                    </div>
                    <div id='published' className={activeTab === 'published' ? 'tab active' : 'tab'} onClick={() => setActiveTab('published')}>
                        Published ({countBlocks('published')})
                    </div>
                </div>
                <div className='generate' id='addBlock' onClick={()=> setShowOverlay("block")}>+ Add Block</div>
                <div className='generate' id='setOrder' onClick={()=> setShowOverlay("order")}><img src='/sparkles.svg' ></img> Generate</div>
            </div>
            <div className='blocks'>
                {
                    // Apply tab filter, search filter, and date range filter sequentially.
                    dateRangeFilter(searchFilter(filterBlocks(activeTab))).length === 0 && activeTab === 'live' 
                    ? <div className='noblocks'>You currently have 0 live blocks.<br></br>
                        Get the <a target="_blank" rel="noreferrer" href='https://chrome.google.com/webstore/detail/newsblok/npionmlffhmmjkiidhdmigfkfapbdjbc'>Extension</a> to start curating content.
                    </div>
                    : dateRangeFilter(searchFilter(filterBlocks(activeTab))).map((block, index) => <Block key={index} block={block} actions={tabActions[activeTab]} refresh={triggerRefresh} />)
                }
            </div>
        </div>
    );
}

const AdminBlocks = () => {

    const [blocks, setBlocks] = React.useState([]);
    const [activeTab, setActiveTab] = React.useState('live');
    const [refresh, setRefresh] = React.useState(false);
    const { getToken } = useAuth();
    const [token, setToken] = useState('');
    const {id} = useParams();

    const fetchToken = async () => {
        const fetchedToken = await getToken();
        setToken(fetchedToken);
        console.log(fetchedToken);
    };

    useEffect(() => {
        fetchToken();
    }, [getToken, refresh]); // getToken is a dependency for this effect

    const triggerRefresh = async () => {
        await fetchToken();  // fetch a new token
        setRefresh(!refresh);
    }

    const filterBlocks = (status) => {
        if (status === 'all') {
            return blocks;
        } else if (status === 'live') {
            return blocks.filter(block => block.status === 'live' || block.status === 'error');
        }
        return blocks.filter(block => block.status === status);
    }
    
    const countBlocks = (status) => {
        return blocks.filter(block => block.status === status).length;
    }

    

    React.useEffect(() => {
          // fetch a new token
         api.get('/block/adminindex/'+id, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
            setBlocks(response.data.blocks);
        })
        .catch(error => console.log(error));

    }, [token,refresh]);

    //refresh every 5 seconds
    React.useEffect(() => {
        const interval = setInterval(() => {
            setRefresh(!refresh);
        }, 5000);
        return () => clearInterval(interval);
    }, [refresh]);

    return (
        <div>
            <h1>Blocks</h1>
            <p className='description'>We are currently in beta. Sometimes blocks have errors processing, we are actively working on fixing it</p>
            <div className='blockshead'>
                <div className='tabs'>
                    <div id='active' className={activeTab === 'live' ? 'tab active' : 'tab'} onClick={() => setActiveTab('live')}>
                        Live ({countBlocks('live')})
                    </div>
                    <div id='archive' className={activeTab === 'archive' ? 'tab active' : 'tab'} onClick={() => setActiveTab('archive')}>
                        Archive ({countBlocks('archive')})
                    </div>
                    <div id='published' className={activeTab === 'published' ? 'tab active' : 'tab'} onClick={() => setActiveTab('published')}>
                        Published ({countBlocks('published')})
                    </div>
                </div>
            </div>
            <div className='blocks'>
                {
                    filterBlocks(activeTab).length === 0 && activeTab === 'live' 
                    ? <div className='noblocks'>User currently has 0 live blocks.</div>
                    : filterBlocks(activeTab).map((block, index) => <AdminBlock key={index} block={block}/>)
                }
            </div>
        </div>
    );
}

export { Blocks, AdminBlocks };
