import React from "react";

const Congratulations = () => {
    return (
        <div className="welcome">
            <h1>Congratulations!</h1>
            <p>You have successfully added your first block, you can continue curating blocks while browsing the internet to generate your newsletter.</p>
            <p>To learn more about the different types of blocks visit the tutorial page on your dashboard.</p>
            <a class='button' href="/onboarded">Go to the Dashboard</a>
        </div>
    );
};

export default Congratulations;