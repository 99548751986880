import React, { useState, useEffect } from 'react';
import { useAuth } from '@clerk/clerk-react';
import api from '../services/api';
import Code from './Code';

const ExtensionToken = () => {
    const { getToken } = useAuth();
    const [token, setToken] = useState('');
    const [generatedToken, setGeneratedToken] = useState('');

    useEffect(() => {
        const fetchToken = async () => {
            const fetchedToken = await getToken();
            setToken(fetchedToken);
        };
        fetchToken();
    }, [getToken]); 

    useEffect(() => {
        if (token) {
            api.get('/token/generate', { headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
                setGeneratedToken(response.data.token);
            })
            .catch((error) => console.error(error));
        }
    }, [token]);

    return (
        <div>
                {generatedToken ? (
                    <Code text={generatedToken}/>
                ) : (
                    <p>Loading...</p>
                )}
        </div>
    );
};

export default ExtensionToken;

