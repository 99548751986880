//Basic Home Component
import React from 'react';
import Sidebar from './Sidebar';
import '../style/global.css';
import { NewsletterList } from './NewsletterList';





const Newsletters = () => {
    return (
        <div className='container'>
            <link href="https://api.fontshare.com/v2/css?f[]=satoshi@900,500,400&display=swap" rel="stylesheet"></link>
            <Sidebar />
            <div className='main'>
                <h1>Generated Newsletters</h1>
                <p className='description'>Here are the newsletters generated by Newsblocks.</p>
                <NewsletterList />
            </div>
        </div>
    );
};

export default Newsletters;