import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar'; 
import api from '../services/api';

import { useAuth } from '@clerk/clerk-react';

const UsageBar = () => {
    const { getToken } = useAuth();
    const [token, setToken] = useState('');
    const [usage, setUsage] = useState(0);
    const [limit, setLimit] = useState(0);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const fetchToken = async () => {
            const fetchedToken = await getToken();
            setToken(fetchedToken);
        };
        fetchToken();
    }, [getToken, refresh]);

    const triggerRefresh = () => {
        setRefresh(!refresh);
    }

    useEffect(() => {
        api.get('/usage', { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
            setUsage(response.data.usage);
            setLimit(response.data.limit);
        })
        .catch(error => console.log(error));
    }, [refresh, token]);

    // Refresh every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            triggerRefresh();
        }, 7000);
        return () => clearInterval(interval);
    }, [refresh]);

    const progress = Math.min(100, Math.round((usage / limit) * 100));    
    
    return (
        <div className='usage-bar'>
            <ProgressBar now={progress} />
        </div>
    );
}

export default UsageBar;
