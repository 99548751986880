import React, { useEffect, useState} from 'react';
import Home from './components/Home';
import Newsletters from './components/Newsletters';
import NewsletterContent from './components/NewsletterContent';
import Settings from './components/Settings';
import Tutorial from './components/Tutorial';
import ExtensionToken from './components/ExtensionToken';
import Onboarding from './components/Onboarding';
import AddBlock from './components/AddBlock';
import { ClerkProvider, SignedIn, SignedOut, RedirectToSignIn, SignIn, SignUp } from '@clerk/clerk-react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { createContext, useContext } from 'react';


import { useAuth } from '@clerk/clerk-react';
import api from './services/api';
import AdminRoute from './components/AdminRoute';
import { AdminHome, AdminNewsletters, AdminBlocks, AdminBlocksPage } from './components/AdminHome';
import Workspaces from './components/Workspaces';
import { WorkspaceProvider } from './components/WorkspaceContext';
import Newsfeed from './components/Newsfeed';
import Billing from './components/Billing';
import Pricing from './components/Pricing';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}
var clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
if(process.env.NODE_ENV=='development') {
  clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY_DEV;
}

const PrivateRoute = ({ children, onboarded }) => {
  const navigate = useNavigate();
  const step = useQuery().get('step');

  useEffect(() => {
    if (!onboarded) {
      navigate(`/onboarding?step=${step || 1}`);
    }
  }, [onboarded, navigate, step]); // Add onboarded, navigate, and step to the dependency array

  return (
    <>
      <SignedIn>
        {children}
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
};

function Onboarded() {
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const { setOnboarded } = useContext(AppContext);

  useEffect(() => {
    const updateOnboarded = async () => {
      const token = await getToken();
      api.put('/usage/onboard', {}, { headers: { Authorization: `Bearer ${token}` } })
        .then(() => {
          setOnboarded(true);
          navigate('/');
        })
        .catch((error) => {
          console.error(error);
        });
    };
    updateOnboarded();
  }, [getToken, navigate, setOnboarded]);

  return null; // Or return a loading spinner, etc.
}

const AppContext = createContext();


function OnboardedWrapper() {
  const { getToken } = useAuth();
  const [onboarded, setOnboarded] = useState(false);
  const [loading, setLoading] = useState(true); // Added

  useEffect(() => {
    const getOnboarded = async () => {
      const token = await getToken();

      api.get('/usage/onboarded', { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
          console.log(response.data.onboarded);
          setOnboarded(response.data.onboarded);
          setLoading(false); // Added
        })
        .catch(error => {
          console.error(error);
          setOnboarded(false);
          setLoading(false); // Added
        });
    };
    getOnboarded();
  }, [getToken]);

  // Loading check added
  if (loading) {
    return <div></div>; // Or any loading spinner component
  }


  return (
    <WorkspaceProvider>
    <AppContext.Provider value={{ onboarded, setOnboarded }}>
      <Router>
        <Routes>
          <Route path="/sign-in/*" element={<SignIn routing="path" path="/sign-in" />} />
          <Route path="/sign-up/*" element={<SignUp routing="path" path="/sign-up" />} />
          <Route path="/" element={
            <PrivateRoute onboarded={onboarded}>
              <Home />
            </PrivateRoute>
          }/>
          <Route path="/newsletter" element={
            <PrivateRoute onboarded={onboarded}>
              <Newsletters />
            </PrivateRoute>
          }/>
          <Route path="/newsletter-issue/:id" element={
            <PrivateRoute onboarded={onboarded}>
              <NewsletterContent />
            </PrivateRoute>
          }/>
          <Route path="/settings" element={
            <PrivateRoute onboarded={onboarded}>
              <Settings />
            </PrivateRoute>
          }/>
          <Route path="/workspaces" element={
            <PrivateRoute onboarded={onboarded}>
              <Workspaces />
            </PrivateRoute>
          }/>
          <Route path="/extension-token" element={
            <PrivateRoute onboarded={onboarded}>
              <ExtensionToken />
            </PrivateRoute>
          }/>
          <Route path="/onboarding" element={
              onboarded 
                  ? <Navigate to="/" />
                  : <PrivateRoute onboarded={onboarded}>
                      <Onboarding />
                    </PrivateRoute>
          } />
          <Route path="/tutorial" element={
            <PrivateRoute onboarded={onboarded}>
              <Tutorial />
            </PrivateRoute>
          }/>
          <Route path="/newsfeed" element={
            <PrivateRoute onboarded={onboarded}>
              <Newsfeed />
            </PrivateRoute>
          }/>
          <Route path="/billing" element={
            <PrivateRoute onboarded={onboarded}>
              <Billing  />
            </PrivateRoute>
          }/>
          <Route path="/pricing" element={
            <PrivateRoute onboarded={onboarded}>
              <Pricing  />
            </PrivateRoute>
          }/>
          <Route path="/onboarded" element={
            <PrivateRoute onboarded={onboarded}>
              <Onboarded />
            </PrivateRoute>
          }/>
          <Route path="/admin" element={
            <AdminRoute>
              <AdminHome />
            </AdminRoute>
          }/>
          <Route path="/admin/newsletters/:id" element={
            <AdminRoute>
              <AdminNewsletters />
            </AdminRoute>
          }/>
          <Route path="/admin/blocks/:id" element={
            <AdminRoute>
              <AdminBlocksPage />
            </AdminRoute>
          }/>
        </Routes>
      </Router>
      </AppContext.Provider>
      </WorkspaceProvider>
  );
}

function App() {
  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <OnboardedWrapper />
    </ClerkProvider>
  );
}

export default App;