//Basic Home Component
import React from 'react';
import Sidebar from './Sidebar';
import '../style/global.css';

const Tutorial = () => {
    return (
        <div className='container'>
            <link href="https://api.fontshare.com/v2/css?f[]=satoshi@900,500,400&display=swap" rel="stylesheet"></link>
            <Sidebar />
            <div className='main'>
                <h1 className='title'>How to use Newsblocks</h1>
                <p className='description'>Check out this video to learn how to use Newsblocks</p>
                <div className='video-container'>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/EpRT3cjoxm4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

            </div>
        </div>
    );
};

export default Tutorial;