import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import '../style/global.css';
import { useWorkspace } from './WorkspaceContext';
import api from '../services/api';
import { useAuth } from '@clerk/clerk-react';

const AddWorkspace = ({ triggerRefresh }) => {
    const [name, setName] = useState('');

    const { getToken } = useAuth();
    const [token, setToken] = useState('');

    const fetchToken = async () => {
        const fetchedToken = await getToken();
        setToken(fetchedToken);
    };

    const addWorkspace = async () => {
        await fetchToken();  // fetch a new token
        api.post('/workspace', { name }, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                window.notify("Workspace "+name+" created");
                setName('');
                triggerRefresh();
            })
            .catch(error => {
                window.notifyRed('Error adding workspace : '+error.response.data.error);
            });
    };

    useEffect(() => {
        fetchToken();
    }, [getToken]);
            
    return (
        <div className='popup-inside'>
        <h1>Add new workspace</h1>
        <div className='add-block'>
            <div className='setting-tab'>
                <div className='setting-tab-title'>Name</div>
                <div className='setting-tab-content'>
                    <input className='block-urls' type='text' placeholder='Workspace name' value={name} onChange={e => setName(e.target.value)} />
                </div>
            </div>
            <div className='setting-submit-container'>
                <button className='generate yellow' onClick={addWorkspace}>Add Workspace</button>
            </div>
        </div> 
        </div>
    );
}

const Workspaces = () => {
    
    const [showOverlay, setShowOverlay] = useState(false);
    const { workspaceId, setWorkspaceId } = useWorkspace();
    const [workspaces, setWorkspaces] = useState([]);
    const [workspaceIdSelected, setWorkspaceIdSelected] = useState('0');

    const { getToken } = useAuth();
    const [token, setToken] = useState('');
    const [refresh, setRefresh] = useState(false);

    const fetchToken = async () => {
        const fetchedToken = await getToken();
        setToken(fetchedToken);
    };

    const triggerRefresh = async () => {
        await fetchToken();  // fetch a new token
        setRefresh(!refresh);
    }

    const selectWorkspace = async () => {
        await fetchToken();  // fetch a new token
        setWorkspaceId(workspaceIdSelected);
    }

    const deleteWorkspace = async () => {
        await fetchToken();  // fetch a new token
        api.delete('/workspace/'+workspaceIdSelected, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                window.notify("Workspace deleted");
                setWorkspaceId('0');
                triggerRefresh();
            })
            .catch(error => {
                window.notifyRed('Error deleting workspace : '+error.response.data.error);
            });
    }

    useEffect(() => {
        fetchToken();
    }, [getToken, refresh]);

    useEffect(() => {
        api.get('/workspace', { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setWorkspaces(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, [token]);

    return (
        <div className='container'>
            <link href="https://api.fontshare.com/v2/css?f[]=satoshi@900,500,400&display=swap" rel="stylesheet"></link>
            <Sidebar />
            <div className='main'>
                {showOverlay && <div className='overlay' onClick={() => setShowOverlay(false)}>
                <div onClick={(e) => e.stopPropagation()}>
                    <AddWorkspace triggerRefresh={triggerRefresh} />
                    </div>
                </div>}
                <h1>Workspaces</h1>
                 
                <div className='blockshead'>
                <p className='description'>Workspaces are a way to organize your newsletters.<br></br> You can create a workspace for each of your interests, or for each of your projects.</p>
                <div className='generate' onClick={() => setShowOverlay(true)}>+ New Workspace</div>
                </div>
                <div className='setting-tab'>
                    <div className='setting-tab-title'>Select Workspace :</div>
                    <div className='setting-tab-content'>
                        <select value={workspaceIdSelected} onChange={e => setWorkspaceIdSelected(e.target.value)}>
                            <option value='0'>Default</option>
                            {workspaces.map(workspace => (
                                <option key={workspace.id} value={workspace._id}>{workspace.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <button className='generate yellow' onClick={selectWorkspace}>Select Workspace</button>
                        <button className='generate yellow' onClick={deleteWorkspace}>Delete Workspace</button>
                    </div>
                </div>
            </div>
        </div>
    );
};



export default Workspaces;
