import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; 
import api from '../services/api';
import ProgressBar from 'react-bootstrap/ProgressBar'; 

import { useParams } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import { useWorkspace } from './WorkspaceContext';

const Newsletter = ({ newsletter }) => {
    const parser = new DOMParser();
    let first300Chars = "Loading...";

    const createdAt = new Date(newsletter.createdAt).getTime();
    const now = Date.now();
    const elapsedSeconds = Math.round((now - createdAt) / 1000);
    const initialProgress = Math.min(Math.round((elapsedSeconds / 300) * 90), 90);
    
    const [progress, setProgress] = React.useState(initialProgress);

    if (newsletter.final) {
        const parsedHtml = parser.parseFromString(newsletter.final, 'text/html');
        const firstParagraph = parsedHtml.getElementsByTagName('p')[0];
        first300Chars = firstParagraph?.textContent.substring(0, 100) + '...' || "Loading...";
    }

    const imageUrl = newsletter.image || "/placeholder-image.png";  

    React.useEffect(() => {
        let progressInterval;
        if (!newsletter.final) {
            progressInterval = setInterval(() => {
                setProgress(prev => (prev < 90 ? prev + 1 : prev));
            }, 3333); 
        }

        return () => clearInterval(progressInterval);
    }, [newsletter.final, newsletter.createdAt]);

    return (
        <Link to={`/newsletter-issue/${newsletter._id}`}> 
            <div className='newsletter'>
                <img className='newsletter-image' src={imageUrl}></img>  
                <div className='newsletter-main'>
                    <div className='newsletter-name'>{newsletter.name}</div>
                    <div className='newsletter-title'>{newsletter.title}</div>
                    <div className='newsletter-content'>{first300Chars}</div>
                    <div className='newsletter-date'>{new Date(newsletter.createdAt).toLocaleString('en-US', {timeZone: 'America/New_York', hour12: true, month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'})}</div>
                </div>
                {!newsletter.final && (
                    <div className='newsletter-loading-overlay'>
                        <div className='loading-text'>Your newsletter is being generated..</div>
                        <ProgressBar now={progress} />
                    </div>
                )}
            </div>
        </Link>
    );
}



const NewsletterList = () => {
    const [newsletters, setNewsletters] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const { getToken } = useAuth();
    const [token, setToken] = useState('');

    
    const {workspaceId} = useWorkspace();

    useEffect(() => {
        const fetchToken = async () => {
            const fetchedToken = await getToken();
            setToken(fetchedToken);
        };
        fetchToken();
    }, [getToken, refresh]);

    const triggerRefresh = () => {
        setRefresh(!refresh);
    }

    useEffect(() => {
        api.get('/newsletter?workspaceId='+workspaceId, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
            console.log(response);
            setNewsletters(response.data.newsletters);
        })
        .catch(error => console.log(error));
    }, [refresh, token]);

    // Refresh every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            triggerRefresh();
        }, 3000);
        return () => clearInterval(interval);
    }, [refresh]);

    return (
        <div className='newsletters'>
            {newsletters.map((newsletter, index) => <Newsletter key={index} newsletter={newsletter} />)}
        </div>
    );
}

const NewsletterListAdmin = () => {
    const [newsletters, setNewsletters] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const { getToken } = useAuth();
    const [token, setToken] = useState('');
    
    const { id } = useParams();

    useEffect(() => {
        const fetchToken = async () => {
            const fetchedToken = await getToken();
            setToken(fetchedToken);
        };
        fetchToken();
    }, [getToken, refresh]);

    const triggerRefresh = () => {
        setRefresh(!refresh);
    }

    useEffect(() => {
        api.get('/newsletter/adminindex/'+id, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
            console.log(response);
            setNewsletters(response.data.newsletters);
        })
        .catch(error => console.log(error));
    }, [refresh, token]);

    // Refresh every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            triggerRefresh();
        }, 3000);
        return () => clearInterval(interval);
    }, [refresh]);

    return (
        <div className='newsletters'>
            {newsletters.map((newsletter, index) => <Newsletter key={index} newsletter={newsletter} />)}
            {newsletters.length === 0 && <div className='no-newsletters'>No newsletters yet.</div>}
        </div>
    );
}

export { NewsletterList, NewsletterListAdmin };