import React, { useEffect, useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import api from '../services/api';

function AdminRoute({ children }) {
  const { getToken } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const token = await getToken();
      api.get('/usage/isadmin', { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
          setIsAdmin(response.data.isAdmin);
        })
        .catch(error => {
          console.error(error);
          setIsAdmin(false);
        });
    };
    checkAdminStatus();
  }, [getToken]);

  if (!isAdmin) {
    return <div>You are not an admin.</div>;
  }

  return (
    <div>
      {children}
    </div>
  );
}

export default AdminRoute;