import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../services/api';

import { useAuth } from '@clerk/clerk-react';
import { Block } from './Block';

const OneBlock = () => {
    const [block, setBlock] = React.useState(null);
    const { getToken } = useAuth();
    const [token, setToken] = useState('');

    const fetchToken = async () => {
        const fetchedToken = await getToken();
        setToken(fetchedToken);
    };

    useEffect(() => {
        fetchToken();
    }, [getToken]); 

    React.useEffect(() => {
        const fetchBlock = () => {
            api.get('/block', { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                const liveBlock = response.data.blocks.find(block => block.status === 'live' || block.status === 'error');
                setBlock(liveBlock);
            })
            .catch(error => console.log(error));
        }
        
        fetchBlock();
        const intervalId = setInterval(fetchBlock, 3000); // Refresh every 3 seconds

        return () => clearInterval(intervalId); // Clean up on unmount
    }, [token]);

    return (
        <div className='oneblock'>
            {
                block ?
                <>
                    <Block block={block} />
                    <div className='spacer'></div>
                    <a className='button' href='/onboarding?step=5'>Continue</a></> :
                <p className='italic'>Patiently waiting for you to curate a block..</p>
            }
        </div>
    );
}

export default OneBlock;
