//Basic Home Component
import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import '../style/global.css';
import api from '../services/api';
import { useAuth } from '@clerk/clerk-react';
import { useWorkspace } from './WorkspaceContext';

// Utility function to fetch token
const useToken = () => {
    const { getToken } = useAuth();
    const [token, setToken] = useState('');

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const fetchedToken = await getToken();
                setToken(fetchedToken);
            } catch (error) {
                console.error("Error fetching token:", error);
            }
        };
        fetchToken();
    }, [getToken]);

    return token;
};

const AddNewsfeed = ({ onAdd }) => {

    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const token = useToken();

    const {workspaceId} = useWorkspace();

    const addNewsfeed = async () => {
        try {
            await api.post('/feed', { name, url, workspaceId }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            window.notify(`Newsfeed ${name} created`);
            setName('');
            setUrl('');
            onAdd();  // Callback to refresh the newsfeed list
        } catch (error) {
            window.notifyRed(`Error adding newsfeed: ${error.response.data.error}`);
        }
    };
            
    return (
        <div className='popup-inside'>
        <h1>Add new RSS Newsfeed</h1>
        <div className='add-block'>
            <div className='setting-tab'>
                <div className='setting-tab-title'>Name</div>
                <div className='setting-tab-content'>
                    <input className='block-urls' type='text' placeholder='Newsfeed name' value={name} onChange={e => setName(e.target.value)} />
                </div>
            </div>
            <div className='setting-tab'>
                <div className='setting-tab-title'>URL</div>
                <div className='setting-tab-content'>
                    <input className='block-urls' type='text' placeholder='RSS Url' value={url} onChange={e => setUrl(e.target.value)} />
                </div>
            </div>
            <div className='setting-submit-container'>
                <button className='generate yellow' onClick={addNewsfeed}>Add Newsfeed</button>
            </div>
        </div> 
        </div>
    );
}

const NewsfeedItem = ({ newsfeed, onDelete }) => {
    const token = useToken();

    const deleteNewsfeed = async () => {
        try {
            await api.delete(`/feed/${newsfeed._id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            window.notify(`Newsfeed ${newsfeed.name} deleted`);
            onDelete();  // Callback to refresh the newsfeed list
        } catch (error) {
            window.notifyRed(`Error deleting newsfeed: ${error.response.data.error}`);
        }
    };

    return (
        <div className='feed'>
            <div className='feed-heading'>
                <h3>{newsfeed.name}</h3>
            </div>
            <p>{newsfeed.url}</p>
            <div className='buttons'>
                <button title="Delete" class="delete" onClick={deleteNewsfeed}><img src="/delete.svg" alt="delete"/></button>
            </div>
        </div>
    );
};

const NewsItem = ({ news }) => {
    const token = useToken();

    const addBlock = async () => {
        try {
            await api.post('/block', { url: news.link, type: 'news' }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            window.notify(`News block added`);
        } catch (error) {
            window.notifyRed(`Error adding news block: ${error.response.data.error}`);
        }
    }

    return (
        <div className='feed'>
            <div className='feed-heading'>
                <h3><a target='_blank' href={news.link}>{news.title}</a></h3>
            </div>
            <p>{news.link}</p>
            <div className='buttons'>
                <button title="Add block" class="addblock" onClick={addBlock}><img src="/addblock.svg" alt="add block"/></button>
            </div>
        </div>
    );
};


const Newsfeed = () => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [activeTab, setActiveTab] = React.useState('feeds');

    const [newsfeeds, setNewsfeeds] = useState([]);
    const [news, setNews] = useState([]);
    const token = useToken();

    const {workspaceId} = useWorkspace();

    const fetchNewsfeeds = async () => {
        try {
            const response = await api.get('/feed?workspaceId='+workspaceId, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setNewsfeeds(response.data);
        } catch (error) {
            console.error("Error fetching newsfeeds:", error);
        }
    };

    const fetchNews = async () => {
        try {
            const response = await api.get('/feed/rssall?workspaceId='+workspaceId, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setNews(response.data);
        } catch (error) {
            console.error("Error fetching news:", error);
        }
    }

    // This effect ensures we fetch newsfeeds on component mount and when 'token' changes.
    useEffect(() => {
        fetchNewsfeeds();
        fetchNews();
    }, [token]);

    const handleNewsfeedAdded = async () => {
        await fetchNewsfeeds(); // Make sure to wait for newsfeeds to be fetched.
        setShowOverlay(false);
    };

    const handleNewsfeedDeleted = async () => {
        await fetchNewsfeeds(); // Again, wait for this operation to complete.
    };

    return (
        <div className='container'>
            <link href="https://api.fontshare.com/v2/css?f[]=satoshi@900,500,400&display=swap" rel="stylesheet"></link>
            <Sidebar />
            <div className='main'>
                {showOverlay && (
                    <div className='overlay' onClick={() => setShowOverlay(false)}>
                        <div onClick={(e) => e.stopPropagation()}>
                            <AddNewsfeed onAdd={handleNewsfeedAdded} />
                        </div>
                    </div>
                )}
                <h1 className='title'>Newsfeeds</h1>
                <p className='description'>Add your preferred RSS feeds to get the latest news from your favorite sources and use as blocks for your newsletters.</p>
                <div className='blockshead'>
                    
                    <div className='tabs'>
                        <div id='news' className={activeTab === 'news' ? 'tab active' : 'tab'} onClick={() => setActiveTab('news')}>
                            News
                        </div>
                        <div id='feeds' className={activeTab === 'feeds' ? 'tab active' : 'tab'} onClick={() => setActiveTab('feeds')}>
                            Feeds
                        </div>
                    </div>
                    <div className='generate' onClick={() => setShowOverlay(true)}>+ Add RSS feed</div>
                </div>
                
                { activeTab === 'feeds' &&
                    <div className='newsfeeds'>
                    {newsfeeds.map(newsfeed => (
                        <NewsfeedItem 
                            key={newsfeed._id} // Make sure to use a unique key value
                            newsfeed={newsfeed}
                            onDelete={handleNewsfeedDeleted}
                        />
                    ))}
                </div>}
                { activeTab === 'news' &&
                    <div className='newsfeeds news'>
                    {news.map(news => (
                        <NewsItem 
                            key={news._id} // Make sure to use a unique key value
                            news={news}
                        />
                    ))}
                    </div>
                }
            </div>
        </div>
    );
};

export default Newsfeed;