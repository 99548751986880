

import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import SettingsUnwrapped from './SettingsUnwrapped';
import '../style/global.css';

const Settings = () => {
    

    return (
        <div className='container'>
            <link href="https://api.fontshare.com/v2/css?f[]=satoshi@900,500,400&display=swap" rel="stylesheet"></link>
            <Sidebar />
            <div className='main'>
                <SettingsUnwrapped />
            </div>
        </div>
    );
};

export default Settings;
