import React, { useState, useEffect } from 'react';
import api from '../services/api';
import '../style/global.css';

import { useAuth } from '@clerk/clerk-react';
import { useWorkspace } from './WorkspaceContext';
import { Block } from './Block';

const UpDown = ({ id, onUp, onDown }) => {
    return (
        <div className='up-down'>
            <img src="/up.svg" onClick={() => onUp(id)}></img>
            <img src="/down.svg" onClick={() => onDown(id)}></img>
        </div>
    );
}

const SetOrderPopup = ({ initialOrder, onOrderSet, closePopup }) => {
    
    const [blocks, setBlocks] = React.useState([]);
    const [order, setOrder] = useState(initialOrder); // Use initialOrder prop
    const { getToken } = useAuth();
    const [token, setToken] = useState('');
    const [refresh, setRefresh] = React.useState(false);
    const [intro, setIntro] = React.useState('');
    const [outro, setOutro] = React.useState('');

    const [useIntroVerbatim, setUseIntroVerbatim] = React.useState(false);
    const [useOutroVerbatim, setUseOutroVerbatim] = React.useState(false);


    const [step, setStep] = React.useState(1);

    const { workspaceId } = useWorkspace();

    const fetchToken = async () => {
        const fetchedToken = await getToken();
        setToken(fetchedToken);
    };
    
    const handleIntroVerbatimChange = (event) => {
        setUseIntroVerbatim(event.target.checked);
    };
    
    const handleOutroVerbatimChange = (event) => {
        setUseOutroVerbatim(event.target.checked);
    };
    

    useEffect(() => {
        fetchToken();
    }, [getToken, refresh]); // getToken is a dependency for this effect

    const moveBlock = (id, direction) => {
        const index = order.indexOf(id);
        if (index > -1) {
            const newOrder = [...order];
            if (direction === 'up' && index > 0) {
                // Swap with the previous block
                [newOrder[index - 1], newOrder[index]] = [newOrder[index], newOrder[index - 1]];
            } else if (direction === 'down' && index < newOrder.length - 1) {
                // Swap with the next block
                [newOrder[index], newOrder[index + 1]] = [newOrder[index + 1], newOrder[index]];
            }
            setOrder(newOrder);
            onOrderSet(newOrder);
        }
    }

    const onUp = (id) => {
        moveBlock(id, 'up');
    }

    const onDown = (id) => {
        moveBlock(id, 'down');
    }

    const generateNewsletter = async () => {
        await fetchToken();  // fetch a new token
        api.get('/block/limit-test?workspaceId='+workspaceId, { headers: { Authorization: `Bearer ${token}` } }).
        then(response => {
            console.log(response);
            if(response.data.success) {
                api.post("/block/generate",{ workspaceId, order, intro, outro, useIntroVerbatim, useOutroVerbatim }, { headers: { Authorization: `Bearer ${token}` } })
                    .then(response => {
                        window.location.href = '/newsletter';
                        window.notify('Redirecting to newsletters...');
                    })
                    .catch(error => {
                        window.notifyRed('Error generating newsletter : '+error.response.data.error);
                    });
            } else {
                window.notifyRed(response.data.message);
            }
        })
        .catch(error => {
            window.notifyRed('Error validating blocks : '+error.response.data.error);
            console.log(error);
        });
        
    }

    React.useEffect(() => {
        // fetch a new token
        api.get('/block?workspaceId=' + workspaceId+"&order=true", { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                const liveBlocks = response.data.blocks.filter(block => block.status === 'live');
                setBlocks(liveBlocks);
                console.log(liveBlocks);
                if(order.length === 0) setOrder(liveBlocks.map(block => block._id));
                
            })
            .catch(error =>{
                console.log(error);
                setRefresh(!refresh);
            });
    }, [token,refresh]);


    // Now whenever you want to sort by type, you can call sortByType();
    // For example, you can call it after fetching blocks: sortByType();

    return (
        <div className='popup-inside'>
            {step == 1 && <>
            <h1>Set Block Order</h1>
            <p className='description'>Put your blocks in the order you want them to be generated within the newsletter.</p>
            <div className='set-order'>
                {order.map(id => blocks.find(block => block._id === id)).filter(Boolean).map(block => (
                    <div className='order-line' key={block._id}>
                        <Block block={block} minimal={true} />
                        <UpDown 
                            id={block._id} 
                            onUp={onUp} 
                            onDown={onDown}
                        />
                    </div>
                ))}
            </div>
            <div className='two_buttons'>
                <div className='generate' id='generate' onClick={() => {closePopup()}}> Cancel</div>
                <div className='generate' id='generate' onClick={() => {setStep(2)}}> Next</div>
            </div>
            </>
            }
            {step == 2 && <>
            <h1>Set a custom intro and closing</h1>
            <p className='description'>You can add an optional custom intro and closing to your newsletter. If you leave this blank, the AI will generate them for you.</p>
            <div className='intro-outro'>
                <div className='setting-tab'>
                    <div className='setting-tab-title'>Intro</div>
                    <div className='setting-tab-content'>
                        <textarea className='block-urls'placeholder='If left blank, the AI will generate an intro for you... ' value={intro} onChange={e => setIntro(e.target.value)} ></textarea>
                    </div>
                </div>
                <div className='setting-checkbox'>
                    <input 
                        type='checkbox' 
                        id='introVerbatim' 
                        name='introVerbatim' 
                        checked={useIntroVerbatim} 
                        onChange={handleIntroVerbatimChange} 
                    />
                    <label for='introVerbatim'>Use this word for word (if unchecked, the AI will enhance your intro)</label>
                </div>
            
                <div className='setting-tab'>
                    <div className='setting-tab-title'>Closing</div>
                    <div className='setting-tab-content'>
                        <textarea className='block-urls' placeholder='If left blank, the AI will generate a closing for you...'  value={outro} onChange={e => setOutro(e.target.value)} ></textarea>
                    </div>
                </div>
                <div className='setting-checkbox'>
                    <input 
                        type='checkbox' 
                        id='outroVerbatim' 
                        name='outroVerbatim' 
                        checked={useOutroVerbatim} 
                        onChange={handleOutroVerbatimChange} 
                    />
                    <label for='outroVerbatim'>Use this word for word (if unchecked, the AI will enhance your closing)</label>
                </div>
            </div>
            
            <div className='generate' id='generate' onClick={generateNewsletter}><img src='/sparkles.svg' ></img> Generate</div>
            <div className='two_buttons'>  
                <div className='generate' id='generate' onClick={() => {closePopup()}}> Cancel</div> 
                <div className='generate' id='generate' onClick={() => {setStep(1)}}> Back</div>
            </div>
            </>}
        </div>
    );
}

export default SetOrderPopup;
