import React from "react";

const Welcome = () => {
    return (
        <div className="welcome">
            <h1>Welcome to Newsblocks!</h1>
            <p>Welcome to Newsblocks! In our quick tutorial, we'll show you how to use our easy, AI-powered tool to create newsletters. You'll learn to pick content and make a full newsletter in just seconds with our Chrome extension. Our guide will help you get to grips with Newsblocks' features, making your newsletter creation faster and easier.<br></br><br></br> Ready to start? Let's go!</p>
            <a class='button' href="/onboarding?step=2">Take the tutorial</a>
            <a class='smalllink' href="/onboarded">Skip to the dashboard</a>
        </div>
    );
};

export default Welcome;