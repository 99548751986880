import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Sidebar from './Sidebar';
import api from '../services/api';
import { useParams } from 'react-router-dom';
import '../style/global.css';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { useAuth } from '@clerk/clerk-react';
import MarkdownRenderer from './Markdown';

const NewsletterContent = () => {
    const [newsletter, setNewsletter] = useState({});
    const [copySuccess, setCopySuccess] = useState(false);
    const { id } = useParams();
    const { getToken } = useAuth();  // Get the getToken function
    const [token, setToken] = useState('');
    const [refresh, setRefresh] = useState(false);

    const [view, setView] = useState('newsletter');

    useEffect(() => {
        const fetchToken = async () => {
            const fetchedToken = await getToken();
            setToken(fetchedToken);
            console.log(fetchedToken);
        };
        fetchToken();
    }, [getToken]);

    useEffect(() => {
        if (token) {
            api.get(`/newsletter/${id}`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    setNewsletter(response.data.newsletter);
                })
                .catch(error => console.log(error));
        }
    }, [token, refresh]);

    const triggerRefresh = () => {
        setRefresh(!refresh);
    };

    useEffect(() => {
        const tweetPlaceholders = document.querySelectorAll('.tweet-embed');
        tweetPlaceholders.forEach((element) => {
          const tweetId = element.getAttribute('data-tweet-id');
          ReactDOM.render(<TwitterTweetEmbed tweetId={tweetId} />, element);
        });
    
        return () => {
          // Clean up
          tweetPlaceholders.forEach((element) => {
            ReactDOM.unmountComponentAtNode(element);
          });
        };
      }, [view, newsletter]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(newsletter.final).then(function() {
            console.log('Copying to clipboard was successful!');
            setCopySuccess(true);
            setTimeout(() => {
                setCopySuccess(false);
            }, 3000); // revert back after 3 seconds
          }, function(err) {
            console.error('Could not copy text: ', err);
          });
    };

    const handleCopyAsRichText = () => {
        // Fetch the newsletter HTML (adjust the selector if needed)
        const newsletterHTML = document.querySelector('.newsletter-page').innerHTML;
    
        // Prepare a text/html version for the clipboard API
        const blob = new Blob([newsletterHTML], { type: 'text/html' });
        const data = [new ClipboardItem({ 'text/html': blob })];
    
        // Write to the clipboard
        navigator.clipboard.write(data).then(() => {
            // Success! Handle user feedback similar to how 
            // you do with other copy successful messages.
            console.log("Rich text copied to clipboard");
        })
        .catch(error => {
            // Handle errors
            console.error("Error copying rich text: ", error);
        });
    };

    const copyBlogPostToClipboard = () => {
        navigator.clipboard.writeText(newsletter.blogPost).then(function() {
            console.log('Copying to clipboard was successful!');
            setCopySuccess(true);
            setTimeout(() => {
                setCopySuccess(false);
            }, 3000); // revert back after 3 seconds
            }, function(err) {
            console.error('Could not copy text: ', err);
        });
    };

    const createBlogPost = () => {
        api.post(`/newsletter/blog`, { id: newsletter._id }, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                console.log(response);
                window.notify('Blog post is being generated...');
                triggerRefresh();
            })
            .catch(error => {
                window.notifyRed('Error creating blog post :'+error.response.data.error);
                console.log(error);
            });
    };

    const createXThread = () => {
        api.post(`/newsletter/x`, { id: newsletter._id }, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                console.log(response);
                window.notify('X Thread is being generated...');
                triggerRefresh();
            })
            .catch(error => {
                window.notifyRed('Error creating X Thread :'+error.response.data.error);
                console.log(error);
            });
    };


    //refresh every 3 seconds if !newsletter.blogPost
    useEffect(() => {
        if (!newsletter.blogPost || newsletter.blogPost == "loading" || !newsletter.xThread || newsletter.xThread == "loading") {
            const interval = setInterval(() => {
                triggerRefresh();
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [newsletter.blogPost]);

    const viewBlogPost = () => {
        setView('blogpost');
    };

    return (
        <div className='container'>
            <link href="https://api.fontshare.com/v2/css?f[]=satoshi@900,500,400&display=swap" rel="stylesheet"></link>
            <Sidebar />
            <div className='main newsissue'>
                <h1>Generated Newsletter</h1>
                <p className='description'>Here is the newsletter generated by Newsblocks, you can copy the content and send it to your subscribers.</p>
                <div className='newsletter-actions'>
                    {view == "newsletter" && <>
                    <div className='generate' id='copyhtml' onClick={copyToClipboard}>
                        <img src="/copy.svg" />
                        {copySuccess ? "Copied!" : "Copy HTML"}
                    </div>
                    <div className='generate' onClick={handleCopyAsRichText}>
                        <img src="/copy-rich.svg" />
                        Copy as Rich Text
                    </div></>
                    }
                    {view == "blogpost" && newsletter.blogPost && newsletter.blogPost != "loading" && 
                    <div className='generate' id='copyhtml' onClick={copyBlogPostToClipboard}>
                        <img src="/copy.svg" />
                        {copySuccess ? "Copied!" : "Copy Blog Post"}
                    </div>}
                    {view == "x" &&
                        <div className='generate' onClick={()=>setView('newsletter')}>
                            <img src="/newsletter.svg" />
                            View Newsletter
                        </div>}

                    {!newsletter.blogPost && <div className='generate' onClick={createBlogPost}><img src="/blog.svg" />
                        Create Blog Post
                    </div>}
                    {newsletter.blogPost == "loading" && <div className='generate'><img src="/blog.svg" />
                        Loading Blog Post...
                    </div>}
                    {newsletter.blogPost && newsletter.blogPost != "loading" && view != "blogpost" && <div className='generate' onClick={viewBlogPost}><img src="/blog.svg" />
                        View Blog Post
                    </div>}
                    {newsletter.blogPost && newsletter.blogPost != "loading" && view == "blogpost" && <div className='generate' onClick={() => setView('newsletter')}><img src="/newsletter.svg" />
                        View Newsletter
                    </div>}
                    {!newsletter.xThread && <div className='generate' onClick={createXThread}><img src="/share.svg" />
                        Create X Thread
                    </div>}
                    {newsletter.xThread == "loading" && <div className='generate'><img src="/share.svg" />
                        Loading X Thread...
                    </div>}
                    {newsletter.xThread && newsletter.xThread != "loading" && view != "x" && <div className='generate' onClick={() => setView('x')}><img src="/share.svg" />
                        View X Thread
                    </div>}
                </div>
                {/*<TwitterTweetEmbed tweetId={"1639341938418540550"} />*/}
                {view == 'blogpost' && <div className='newsletter-page'>
                    <MarkdownRenderer markdown={newsletter.blogPost} />
                    </div>}
                {view == 'newsletter' &&
                <div className='newsletter-page' dangerouslySetInnerHTML={{ __html: newsletter.final }}>
                </div>}
                {view == 'x' &&
                <div className='newsletter-page' dangerouslySetInnerHTML={{ __html: newsletter.xThread }}>
                </div>}
                
            </div>
        </div>
    );
}

export default NewsletterContent;
