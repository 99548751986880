/* similar to Settings.js but only the content inside main*/

import React, { useState, useEffect } from 'react';
import api from '../services/api';
import ExtensionToken from './ExtensionToken';
import '../style/global.css';

import { useAuth } from '@clerk/clerk-react';
import { useWorkspace } from './WorkspaceContext';

const SettingsUnwrapped = ({onboarding=false}) => {
    const [name, setName] = useState('');
    const [prompt, setPrompt] = useState('');
    const [tone, setTone] = useState('informative');
    const [sources, setSources] = useState(false);
    const [images, setImages] = useState(false);
    const [emojis, setEmojis] = useState(false);
    const [language, setLanguage] = useState('en'); // en, fr, es, de, it, pt
    const [advanced_prompt, setAdvancedPrompt] = useState('');
    const [api_key, setApiKey] = useState('');
    
    const {workspaceId} = useWorkspace();
    
    const { getToken } = useAuth();
    const [token, setToken] = useState('');

    const fetchToken = async () => {
        const fetchedToken = await getToken();
        setToken(fetchedToken);
    };

    
    useEffect(() => {
        
        fetchToken();
    }, [getToken]);

    useEffect(() => {
        api.get('/settings?workspaceId='+workspaceId, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                const settings = response.data;
                setName(settings.name);
                setPrompt(settings.prompt);
                setTone(settings.tone);
                setSources(settings.sources);
                setImages(settings.images);
                setEmojis(settings.emojis);
                setLanguage(settings.language);
                setAdvancedPrompt(settings.advanced_prompt);
                setApiKey(settings.api_key);
            })
            .catch(error => {
                console.log(error);
            });
    }, [token]); // You need to include the token in your dependency list to ensure the effect runs whenever the token changes

    const updateSettings = async () => {
        await fetchToken();  // fetch a new token

        api.put('/settings', { name, prompt, tone, sources, images, emojis, language, advanced_prompt, api_key, workspaceId }, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                if(onboarding) {
                    window.location.href = '/onboarding?step=3';
                }else{
                    console.log(response.data);
                    window.notify('Updated settings successfully')
                }
            })
            .catch(error => {
                alert('Error while updating settings')
                console.log(error);
            });
    }

    return (
        <>
            <h1>Newsletter AI Settings</h1>
                {!onboarding && <p className='description'>Set up your AI parameters to create the most adapted newsletter.</p>}
                {onboarding && <p className='description'>First set up your AI settings that will give the AI direction on how you want to generate your newsletter<br></br>Don't worry you can always change this later</p>}
                <div className='setting-tab'>
                    <div className='setting-tab-title'>Newsletter name</div>
                    <div className='setting-tab-content'>
                        <input id='name' type='text' placeholder='Newsletter name' value={name} onChange={e => setName(e.target.value)}></input>
                    </div>
                </div>
                <div className='setting-tab'>
                    <div className='setting-tab-title'>What is your newsletter about</div>
                    <div className='setting-tab-content'>
                        <textarea id='prompt' placeholder='Write a few sentences about your newsletter. This will be used for intros and give content to the AI.' value={prompt} onChange={e => setPrompt(e.target.value)}></textarea>
                    </div>
                </div>
                {/* Tone : Select from 5 options : informative, engaging, inspirational, conversational, thought-provoking*/}
                <div className='setting-tab'>
                    <div className='setting-tab-title'>Tone</div>
                    <div className='setting-tab-content'>
                        <select id='tone' name='tone' value={tone} onChange={e => setTone(e.target.value)}>
                            <option value='informative'>Informative</option>
                            <option value='engaging'>Engaging</option>
                            <option value='inspirational'>Inspirational</option>
                            <option value='conversational'>Conversational</option>
                            <option value='thought-provoking'>Thought-provoking</option>
                        </select>
                    </div>
                </div>
                {/* Language : Select from 6 options : en, fr, es, de, it, pt*/}
                <div className='setting-tab'>
                    <div className='setting-tab-title'>Language</div>
                    <div className='setting-tab-content'>
                        <select id='language' name='language' value={language} onChange={e => setLanguage(e.target.value)}>
                            <option value='english'>English</option>
                            <option value='french'>French</option>
                            <option value='spanish'>Spanish</option>
                            <option value='german'>German</option>
                            <option value='italian'>Italian</option>
                            <option value='portuguese'>Portuguese</option>
                            <option value='chinese'>Chinese</option>
                        </select>
                    </div>
                </div>
                {/* Advanced prompt : 1 textarea */}
                <div className='setting-tab'>
                    <div className='setting-tab-title'>Advanced prompt (optional)</div>
                    <div className='setting-tab-content'>
                        <textarea id='advanced_prompt' placeholder='You can add any extra information on how you want your newsletter to be generated here. Style, special rules, etc.. play around and test to craft your perfect prompt' value={advanced_prompt} onChange={e => setAdvancedPrompt(e.target.value)}></textarea>
                    </div>
                </div>
                {/* Features : 2 checkboxes */}
                <div className='setting-tab'>
                    <div className='setting-tab-title'>Features</div>
                    <div className='setting-tab-content'>
                        <div className='setting-tab-checkbox'>
                            <input type='checkbox' id='feature1' name='feature1' value='feature1' checked={sources} onChange={e => setSources(e.target.checked)}></input>
                            <label htmlFor='feature1'>Sources</label>
                        </div>
                        <div className='setting-tab-checkbox'>
                            <input type='checkbox' id='feature2' name='feature2' value='feature2' checked={images} onChange={e => setImages(e.target.checked)}></input>
                            <label htmlFor='feature2'>Images</label>
                        </div>
                        <div className='setting-tab-checkbox'>
                            <input type='checkbox' id='feature3' name='feature3' value='feature3' checked={emojis} onChange={e => setEmojis(e.target.checked)}></input>
                            <label htmlFor='feature3'>Emojis</label>
                        </div>
                    </div>
                </div>
                {/* Extension token */}
                {!onboarding && <><div className='setting-tab'>
                    <div className='setting-tab-title'>Extension Token</div>
                    <div className='setting-tab-content'>
                        <ExtensionToken />
                    </div>
                </div>
                <div className='setting-tab'>
                    <div className='setting-tab-title'>Use your own OpenAI API Key (Agency ONLY)</div>
                    <div className='setting-tab-content'>
                        <input id='apikey' type='text' placeholder='API KEY' value={api_key} onChange={e => setApiKey(e.target.value)}></input>
                    </div>
                </div></>}
                <div className='setting-submit-container'>
                <button className='generate yellow' onClick={updateSettings}>Update Settings</button>
            </div>
        </>
    );
}

export default SettingsUnwrapped;
