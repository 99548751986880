import React from 'react';
import {marked} from 'marked';

const MarkdownRenderer = ({ markdown }) => {
    const createMarkup = () => {
        return {__html: marked(markdown)};
    };

    return <div dangerouslySetInnerHTML={createMarkup()} />;
};

export default MarkdownRenderer;
