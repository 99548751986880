//component that takes a prop text and displays it and when clicked copies it to the clipboard and shows Copied instead of the text for 2 seconds

import React, { useState } from 'react';
import '../style/global.css';

const Code = ({ text }) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    return (
        <div className='code'>
            <div className='code-text' onClick={copyToClipboard}>{copied ? 'Copied!' : text}</div>
        </div>
    );
}

export default Code;