import React, { useState, useEffect } from 'react';
import { useWorkspace } from './WorkspaceContext';
import api from '../services/api';
import { useAuth } from '@clerk/clerk-react';

const Topbar = () => {

    const [workspaces, setWorkspaces] = useState([]);
    const { workspaceId } = useWorkspace();
    const [workspaceName, setWorkspaceName] = useState('Default');

    const { getToken } = useAuth();
    const [token, setToken] = useState('');

    const fetchToken = async () => {
        const fetchedToken = await getToken();
        setToken(fetchedToken);
    };

    useEffect(() => {
        fetchToken();
    }, [getToken]);

    useEffect(() => {
        api.get('/workspace', { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setWorkspaces(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }, [token]);

    useEffect(() => {
        const checkWorkspaceId = () => {
            if(!workspaceId || workspaceId == "0") {
                setWorkspaceName('Default');
            } else{
                
                const workspace = workspaces.find(ws => ws._id === workspaceId);
                if (workspace) {
                    setWorkspaceName(workspace.name);
                }
            }
        };

        // Check workspaceId immediately
        checkWorkspaceId();

        // Set interval to check workspaceId every 3 seconds
        const interval = setInterval(checkWorkspaceId, 3000);

        // Clear the interval when component is unmounted
        return () => clearInterval(interval);

    }, [workspaceId, workspaces]);

    return (
        <div className="topbar" onClick={() => window.location.href = '/workspaces'}>
            <p>Current workspace : <span>{workspaceName}</span></p>
        </div>
    )
}

export default Topbar;
