import React, { useEffect, useState } from 'react';
import Blocks, { AdminBlocks } from './Blocks';
import Sidebar from './Sidebar';
import '../style/global.css';
import { useAuth } from '@clerk/clerk-react';
import api from '../services/api';
import { NewsletterListAdmin } from './NewsletterList';
import Code from './Code';

const UpdateSubscription = ({ userId, refresh }) => {
    
    const { getToken } = useAuth();
    const [token, setToken] = useState('');
    const [tier, setTier] = useState('free');

    useEffect(() => { 
        const fetchToken = async () => {
            const fetchedToken = await getToken();
            setToken(fetchedToken);
        };
        fetchToken();
    }, [getToken]);

    const updateSubscription = async () => {
        var subscriptionId = 'admin';
        api.put('/usage/changeplan', {userId, tier, subscriptionId }, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                console.log(response);
                window.notify('Subscription updated successfully');
                refresh();
            })
            .catch(error => {
                console.error(error);
                window.notifyRed('Error updating subscription');
            });
    };

    return (
        <div className='popup-inside'>
        <h1>Update subscription</h1>
        <div className='add-block'>
            <div className='setting-tab'>
                <div className='setting-tab-title'>Subscription Type :</div>
                <div className='setting-tab-content'>
                    <select className='block-urls' value={tier} onChange={(e) => setTier(e.target.value)}>
                        <option value='free'>Free Plan</option>
                        <option value='pro'>Pro Plan</option>
                        <option value='agency'>Agency Plan</option>
                    </select>
                </div>
            </div>
            <div className='setting-submit-container'>
                <button className='generate yellow' onClick={updateSubscription}>Update</button>
            </div>
        </div> 
        </div>
    );
}

const UsageData = () => {

    const { getToken } = useAuth();
    const [userId, setUserId] = useState('');
    const [token, setToken] = useState('');
    const [usage, setUsage] = useState(0);
    const [limit, setLimit] = useState(0);
    const [subscription, setSubscription] = useState('');
    const [tier, setTier] = useState('free');

    const [userCount, setUserCount] = useState(0);
    const [newsletterCount, setNewsletterCount] = useState(0);
    const [blockCount, setBlockCount] = useState(0);

    const [showOverlay, setShowOverlay] = useState(false);

    const getUsageData = async () => {
        api.get('/usage/adminusagedata/'+userId, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setUsage(response.data.usage);
                setLimit(response.data.limit);
                if(response.data.subscriptionId) {
                    setSubscription('Pro Plan');
                    if(response.data.subscriptionId == "lifetime") {
                        setSubscription('Pro Plan (Lifetime)');
                    }
                setTier(response.data.tier);
                }else {
                    setSubscription('Free Plan');
                }
            })
            .catch(error => {
                console.error(error);
            });
    };
    
    const getUserCount = async () => {
        api.get('/usage/count', { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setUserCount(response.data.count);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const getNewsletterCount = async () => {
        api.get('/newsletter/count', { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setNewsletterCount(response.data.count);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const getBlockCount = async () => {
        api.get('/block/count', { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setBlockCount(response.data.count);
            })
            .catch(error => {
                console.error(error);
            });
    };


    useEffect(() => {
        const fetchToken = async () => {
            const fetchedToken = await getToken();
            setToken(fetchedToken);
            console.log(fetchedToken);
        };
        fetchToken();

        
    }, [getToken]);

    useEffect(() => {
        getUserCount();
        getNewsletterCount();
        getBlockCount();
    }, [token]);

    return (
        <div className='admin-panel'>
            {showOverlay && <div className='overlay' onClick={() => setShowOverlay(false)}>
                <div onClick={(e) => e.stopPropagation()}>
                    <UpdateSubscription userId={userId} refresh={getUsageData} />
                    </div>
                </div>}
            <h2>Fetch User Data :</h2>
            <div className='userfetchform'>
            <input type='text' placeholder='User ID' onChange={(e) => setUserId(e.target.value)} value={userId} />
            <div className='button' onClick={getUsageData}>Get Data</div></div>
            <div className='setting-tab'>
                <div className='setting-tab-title'>Usage :</div>
                <div className='setting-tab-content'>
                    {usage.toFixed(0)}/{limit}
                </div>
            </div>
            <div className='setting-tab'>
                <div className='setting-tab-title'>Subscription :</div>
                <div className='setting-tab-content withbutton'>
                    <div>
                {tier}</div>
                <div className='button' onClick={() => setShowOverlay(true)}>Update Subscription</div>
                </div>
            </div>
            <div className='setting-tab'>
                <div className='setting-tab-title'>Newsletters :</div>
                <div className='setting-tab-content'>
                <a href={'/admin/newsletters/'+userId} target='_blank' className='simplelink'>View Newsletters</a><br></br>
                </div>
            </div>
            <div className='setting-tab'>
                <div className='setting-tab-title'>Blocks :</div>
                <div className='setting-tab-content'>
                <a href={'/admin/blocks/'+userId} target='_blank' className='simplelink'>View Blocks</a>
                </div>
            </div>
            <h2>Global Stats</h2>
            <div className='setting-tab'>
                <div className='setting-tab-title'>Users :</div>
                <div className='setting-tab-content'>
                    {userCount}
                </div>
            </div>
            <div className='setting-tab'>
                <div className='setting-tab-title'>Newsletters :</div>
                <div className='setting-tab-content'>
                    {newsletterCount}
                </div>
            </div>
            <div className='setting-tab'>
                <div className='setting-tab-title'>Blocks :</div>
                <div className='setting-tab-content'>
                    {blockCount}
                </div>
            </div>
            
            
        </div>
    );

};

const GenerateCustomCheckout = () => {

    const { getToken } = useAuth();
    const [token, setToken] = useState('');

    const [userId, setUserId] = useState('');
    const [plan, setPlan] = useState('pro_monthly');
    const [discount, setDiscount] = useState('');

    const [checkoutLink, setCheckoutLink] = useState('');

    const fetchToken = async () => {
        const fetchedToken = await getToken();
        setToken(fetchedToken);
        console.log(fetchedToken);
    };

    useEffect(() => {
        fetchToken();
    }, [getToken]);

    const generateCheckout = async () => {
        //fetch token first
        await fetchToken();
        api.get('/usage/admin-generate-checkout/'+plan+'?userId='+userId+'&coupon='+discount, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setCheckoutLink(response.data.url);
            })
            .catch(error => {
                console.error(error);
            }
            );
    };

    return (
        <div className='admin-panel'>
            <h2>Generate Custom Checkout :</h2>
            <div className='setting-tab'>
                <div className='setting-tab-title'>User ID :</div>
                <div className='setting-tab-content'>
                    <input type='text' placeholder='User ID' value={userId} onChange={(event) => setUserId(event.target.value)} />
                </div>
            </div>
            <div className='setting-tab'>
                <div className='setting-tab-title'>Subscription Type :</div>
                <div className='setting-tab-content'>
                    <select className='block-urls' value={plan} onChange={(event) => setPlan(event.target.value)}>
                        <option value='pro_monthly'>Pro Plan (Monthly)</option>
                        <option value='pro_yearly'>Pro Plan (Yearly)</option>
                        <option value='agency_monthly'>Agency Plan (Monthly)</option>
                        <option value='agency_yearly'>Agency Plan (Yearly)</option>
                    </select>
                </div>
            </div>
            <div className='setting-tab'>
                <div className='setting-tab-title'>Discount :</div>
                <div className='setting-tab-content'>
                    <input type='text' placeholder='Discount Code' value={discount} onChange={(event) => setDiscount(event.target.value)} />
                </div>
            </div>
            <div className='setting-tab'>
                <div className='button' onClick={generateCheckout}>Generate Checkout</div>
            </div>
            {checkoutLink && <div className='setting-tab'>
                <div className='setting-tab-title'>Checkout Link :</div>
                <div className='setting-tab-content'>
                    <Code text={checkoutLink} />
                </div>
            </div>}
        </div>
    );
};


const AdminHome = () => {
    
    return (
        <div className='container'>
            <link href="https://api.fontshare.com/v2/css?f[]=satoshi@900,500,400&display=swap" rel="stylesheet"></link>
            <Sidebar />
            <div className='main'>
                <UsageData />
                <GenerateCustomCheckout />
            </div>
        </div>
    );
};

const AdminNewsletters = () => {

    return (
        <div className='container'>
            <link href="https://api.fontshare.com/v2/css?f[]=satoshi@900,500,400&display=swap" rel="stylesheet"></link>
            <Sidebar />
            <div className='main'>
                <NewsletterListAdmin />
            </div>
        </div>
    );
};

const AdminBlocksPage = () => {

    return (
        <div className='container'>
            <link href="https://api.fontshare.com/v2/css?f[]=satoshi@900,500,400&display=swap" rel="stylesheet"></link>
            <Sidebar />
            <div className='main'>
                <AdminBlocks />
            </div>
        </div>
    );

};

export { AdminHome, AdminNewsletters, AdminBlocksPage };
