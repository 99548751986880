
import React, { useState } from 'react';
import Sidebar from './Sidebar';
import '../style/global.css';
import { useAuth } from '@clerk/clerk-react';
import api from '../services/api';

const Pricing = () => {
    const { getToken } = useAuth();
    const [activeTab, setActiveTab] = useState('monthly');
    
    const [discountCode, setDiscountCode] = useState('');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    /*
    const upgradeAccount = async () => {
        var token = await getToken();
        console.log(token);
        api.get('/usage/create-checkout', { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                //const url = response.data.data.attributes.url;
                //window.LemonSqueezy.Url.Open(url);
                const url = response.data.url;
                window.open(url);
            })
            .catch(error => {
                console.log(error);
            });
    };*/


    const handleDiscountChange = (e) => {
        setDiscountCode(e.target.value);
    };

    const checkout = async (plan) => {
        var token = await getToken();
        const params = new URLSearchParams();
        params.append('coupon', discountCode); // Add coupon as a query parameter

        api.get(`/usage/create-checkout/${plan}`, {
            headers: { Authorization: `Bearer ${token}` },
            params: params // Pass the params object as the 'params' option
        })
        .then(response => {
            const url = response.data.url;
            window.open(url);
        })
        .catch(error => {
            console.log(error);
        });
    }

    const contactUs = async () => {
        window.open('mailto:dan@newsblocks.ai');
    }

    return (
        <div className='container'>
            <link href="https://api.fontshare.com/v2/css?f[]=satoshi@900,500,400&display=swap" rel="stylesheet"></link>
            <Sidebar />
            <div className='main'>
                <h1 className='title center'>Simple, transparent pricing</h1>
                <p className='description center'>No contracts. No surprise fees.</p>
                <div className='period-tabs'>
                    <div className={`period-tab ${activeTab === 'monthly' ? 'active' : ''}`} onClick={() => handleTabClick('monthly')}>Monthly</div>
                    <div className={`period-tab ${activeTab === 'yearly' ? 'active' : ''}`} onClick={() => handleTabClick('yearly')}>Yearly</div>
                </div>
                <div className='pricing-container'>
                    <div className='pricing'>
                        {activeTab === "monthly" &&
                        <div className='price'>
                            $14.99<span>/month</span>
                        </div>}
                        {activeTab === 'yearly' && <>
                        <div className='price'>
                            $399<span>/year</span>
                        </div>
                        <div className='price off'>
                            <span><span className='barred'>$588</span>  -30%</span>
                        </div></>}
                        <div className='title'>
                            Pro Plan
                        </div>
                        <div className='description'>
                            For creators and small companies
                        </div>
                        <div className='features'>
                            <div className='feature'>
                                <img className='icon' src='/check.svg' alt='check' />
                                <span>Up to 100 newsletters/month</span>
                            </div>
                            <div className='feature'>
                                <img className='icon' src='/check.svg' alt='check' />
                                <span>Up to 3 workspaces</span>
                            </div>
                            <div className='feature'>
                                <img className='icon' src='/check.svg' alt='check' />
                                <span>24/7 support</span>
                            </div>
                        </div>
                        {activeTab === "monthly" &&
                        <div className='button' onClick={() => checkout('pro_monthly')}>Choose Plan</div>
                        }
                        {activeTab === "yearly" &&
                        <div className='button' onClick={() => checkout('pro_yearly')}>Choose Plan</div>
                        }

                    </div>
                    <div className='pricing popular'>
                        <div className='highlight'>
                            Most Popular
                        </div>
                        {activeTab === "monthly" &&
                        <div className='price'>
                            $99<span>/month</span>
                        </div>}
                        {activeTab === 'yearly' && <>
                        <div className='price'>
                            $799<span>/year</span>
                        </div>
                        <div className='price off'>
                            <span><span className='barred'>$1188</span>  -30%</span>
                        </div></>}
                        <div className='title'>
                            Agency
                        </div>
                        <div className='description'>
                        For agencies and teams
                        </div>
                        <div className='features'>
                            <div className='feature'>
                                <img className='icon' src='/check.svg' alt='check' />
                                <span>Up to 300 newsletters/month</span>
                            </div>
                            <div className='feature'>
                                <img className='icon' src='/check.svg' alt='check' />
                                <span>Unlimited workspaces</span>
                            </div>
                            <div className='feature'>
                                <img className='icon' src='/check.svg' alt='check' />
                                <span>24/7 priority support</span>
                            </div>
                        </div>
                        {activeTab === "monthly" &&
                        <div className='button' onClick={() => checkout('agency_monthly')}>Choose Plan</div>
                        }
                        {activeTab === "yearly" &&
                        <div className='button' onClick={() => checkout('agency_yearly')}>Choose Plan</div>
                        }
                    </div>
                    <div className='pricing'>
                        <div className='price'>
                            $2,499<span>/month</span>
                        </div>
                        <div className='title'>
                            Turnkey Bi-Weekly
                        </div>
                        <div className='description'>
                        All research, curation, and writing done by our professional writers
                        </div>
                        <div className='features'>
                        <div className='feature'>
                                <img className='icon' src='/check.svg' alt='check' />
                                <span>Delivered to you for distribution at your deadlines</span>
                            </div>
                            <div className='feature'>
                                <img className='icon' src='/check.svg' alt='check' />
                                <span>Up to 12 complete newsletter issues per month</span>
                            </div>
                        </div>
                        <div className='button' onClick={contactUs}>Contact us</div>
                    </div>
                    <div className='pricing'>
                        <div className='price'>
                            $4,999<span>/month</span>
                        </div>
                        <div className='title'>
                        Turnkey Daily
                        </div>
                        <div className='description'>
                        All research, curation, and writing done by our professional writers
                        </div>
                        <div className='features'>
                            <div className='feature'>
                                <img className='icon' src='/check.svg' alt='check' />
                                <span>Delivered to you for distribution at your deadlines</span>
                            </div>
                            <div className='feature'>
                                <img className='icon' src='/check.svg' alt='check' />
                                <span>Up to 30 complete newsletter issues per month</span>
                            </div>
                            
                        </div>
                        <div className='button' onClick={contactUs}>Contact us</div>
                    </div>
                    
                </div>
                {/*<div className='discount_container'>
                    <h3>I have a discount :</h3>
                    <input 
                        className='discount'
                        type='text' 
                        placeholder='Enter your code' 
                        value={discountCode}
                        onChange={handleDiscountChange} 
                    />
                    </div>*/}
            </div>
        </div>
    );
};

export default Pricing;