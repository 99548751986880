import React, { useEffect, useState } from 'react';
import OnboardingSidebar from './OnboardingSidebar';
import SettingsUnwrapped from './SettingsUnwrapped';
import Welcome from './onboarding/Welcome';
import { useLocation } from 'react-router-dom';
import '../style/global.css';
import Extension from './onboarding/Extension';
import FirstBlock from './onboarding/FirstBlock';
import Congratulations from './onboarding/Congratulations';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Onboarding = () => {
    const step = useQuery().get('step');
    return (
        <div className='container'>
            <link href="https://api.fontshare.com/v2/css?f[]=satoshi@900,500,400&display=swap" rel="stylesheet"></link>
            <div className='main onboarding'>
                {(step==1 || !step) && <Welcome />}
                {step==2 && <SettingsUnwrapped onboarding={true} />}
                {step==3 && <Extension />}
                {step==4 && <FirstBlock />}
                {step==5 && <Congratulations />}
            </div>
            <OnboardingSidebar />
        </div>
    );
};

export default Onboarding;
