import React from "react";
import ExtensionToken from "../ExtensionToken";

const Extension = () => {
    return (
        <div className="welcome">
            <h2>Download the Chrome Extension</h2>
            <p>Download our Chrome extension. Once installed, enter the provided token into the extension. This connects your extension to Newsblok. After connection, click the button below to proceed. Simple, right?</p>
            <h3>Step 1 : Get the extension</h3>
            <a target='_blank' className='button' href="https://chrome.google.com/webstore/detail/newsblok/npionmlffhmmjkiidhdmigfkfapbdjbc">Get the extension</a>
            <h3>Step 2 : Copy the token into your extension modal</h3>
            <ExtensionToken />
            <p> Once connected click the button below</p>
            <a class='button' href="/onboarding?step=4">Got it, i'm connected</a>
        </div>
    );
};

export default Extension;