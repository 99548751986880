import React, { useState, useEffect } from 'react';
import { useAuth } from '@clerk/clerk-react';
import api from '../services/api';


const Block = ({ block, actions, refresh, minimal=false  }) => {
    const { getToken } = useAuth();
    const [blockType, setBlockType] = useState('loading...');
    const [className, setClassName] = useState('block loading');
    const [typeClassName, setTypeClassName] = useState('type loading');
    const [hoverBlockType, setHoverBlockType] = useState(null);

    const apiCall = async (method, path) => {
        const token = await getToken();
        const config = { headers: { 'Authorization': `Bearer ${token}` } };
      
        switch (method) {
          case 'get':
          case 'delete':
            return api[method](path, config);
          case 'post':
          case 'put':
            return api[method](path, null, config);
          default:
            throw new Error(`Unsupported method: ${method}`);
        }
    };
    
    function switchBlock(){
        //api call
        if(actions){
        apiCall('post', `/block/switch/${block._id}`)
            .then(response => {
            console.log(response);
            refresh();
            })
            .catch(error => {
                window.notifyRed('Error switching block :'+error.response.data.error);
                console.log(error);
            });
        }
    }

    useEffect(() => {
        
        if(block.status === 'error') {
            setClassName('block error');
            setBlockType('error');
            setTypeClassName('type error');
        } else if(block.processed) {
            setClassName('block processed');
            setBlockType(block.type);
            setTypeClassName('type ' + block.type);
        } else {
            setClassName('block loading');
            setBlockType('loading');
            setTypeClassName('type loading');
        }
        if(actions){
        if (hoverBlockType) {
            setBlockType('Switch');
            setTypeClassName('type ' + block.type);
        }}
    }, [block, hoverBlockType]);
    
    return (
        <div className={className}>
            
            <div className='heading'>
                <h3><a target='_blank' title='Open URL' href={block.url}>{block.title}</a></h3>
                {block.type == "quote" &&
                    <p className='type quote'>Quote</p>}
                {block.type == "thought" &&
                    <p className='type thought'>Thought</p>}
                {block.type != "quote" && block.type != "thought" && <p className={typeClassName}
                    onMouseEnter={() => setHoverBlockType('Switch')} 
                    onMouseLeave={() => setHoverBlockType(null)}
                    onClick={() => switchBlock()}
                    >
                        {blockType}
                    
                </p>}
            </div>
            {!minimal && <p>{new Date(block.createdAt).toLocaleString('en-US', {timeZone: 'America/New_York', hour12: true, month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'})}</p>}
            {actions &&
            <div className='buttons'>
                {actions.includes('archive') && (
                <button
                    title='Archive'
                    className='archive'
                    onClick={() => {
                    apiCall('post', `/block/archive/${block._id}`)
                        .then(response => {
                        console.log(response);
                        refresh();
                        })
                        .catch(error => {
                            window.notifyRed('Error archiving block');
                            console.log(error);
                        });
                    }}
                >
                    <img src='/archive.svg' alt='archive' />
                </button>
                )}
                {actions.includes('delete') && (
                <button
                    title='Delete'
                    className='delete'
                    onClick={() => {
                    apiCall('delete', `/block/${block._id}`)
                        .then(response => {
                        console.log(response);
                        refresh();
                        })
                        .catch(error => {
                            window.notifyRed('Error deleting block');
                            console.log(error);
                        });
                    }}
                >
                    <img src='/delete.svg' alt='delete' />
                </button>
                )}
                {actions.includes('activate') && (
                <button
                    title='Activate'
                    className='activate'
                    onClick={() => {
                    apiCall('post', `/block/live/${block._id}`)
                        .then(response => {
                        console.log(response);
                        refresh();
                        })
                        .catch(error => {
                            window.notifyRed('Error activating block');
                            console.log(error);
                        });
                    }}
                >
                    <img src='/activate.svg' alt='activate' />
                </button>
                )}
            </div>}
        </div>
    );
};

const AdminBlock = ({ block }) => {
    const [blockType, setBlockType] = useState('loading...');
    const [className, setClassName] = useState('block loading');
    const [typeClassName, setTypeClassName] = useState('type loading');
    
    useEffect(() => {
        
        if(block.status === 'error') {
            setClassName('block error');
            setBlockType('error');
            setTypeClassName('type error');
        } else if(block.processed) {
            setClassName('block processed');
            setBlockType(block.type);
            setTypeClassName('type ' + block.type);
        } else {
            setClassName('block loading');
            setBlockType('loading');
            setTypeClassName('type loading');
        }
        
    }, [block]);
    
    return (
        <div className={className}>
            
            <div className='heading'>
                <h3><a target='_blank' title='Open URL' href={block.url}>{block.title}</a></h3>
                <p className={typeClassName}>
                        {blockType}
                </p>
            </div>
            <p>{new Date(block.createdAt).toLocaleString('en-US', {timeZone: 'America/New_York', hour12: true, month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'})}</p>
        </div>
    );
};

export { Block, AdminBlock };