import { createContext, useContext, useState, useEffect } from 'react';

const WorkspaceContext = createContext();

export function WorkspaceProvider({ children }) {
    // Initialize the state with the value from localStorage
    const [workspaceId, setWorkspaceId] = useState(() => {
      return sessionStorage.getItem('selectedWorkspaceId') || '0';
    });
  
    // Use useEffect to save the selected workspace ID to localStorage whenever it changes
    useEffect(() => {
      sessionStorage.setItem('selectedWorkspaceId', workspaceId);
    }, [workspaceId]);
  
    return (
      <WorkspaceContext.Provider value={{ workspaceId, setWorkspaceId }}>
        {children}
      </WorkspaceContext.Provider>
    );
  }
  

export function useWorkspace() {
  return useContext(WorkspaceContext);
}
