import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import '../style/global.css';
import api from '../services/api';
import { useAuth } from '@clerk/clerk-react';

// Utility function to fetch token
const useToken = () => {
    const { getToken } = useAuth();
    const [token, setToken] = useState('');

    useEffect(() => {
        const fetchToken = async () => {
            try {
                const fetchedToken = await getToken();
                setToken(fetchedToken);
            } catch (error) {
                console.error("Error fetching token:", error);
            }
        };
        fetchToken();
    }, [getToken]);

    return token;
};

const Billing = () => {

    const token = useToken();
    const [tier, setTier] = useState('Free Trial');
    const [trial, setTrial] = useState('Free Trial expired');

    //call usage/subscription-type
    useEffect(() => {
        api.get('/usage/subscription-type', { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setTier(response.data.subscriptionType);
            })
            .catch(error => {
                console.error(error);
            });
    }, [token]);

    //call usage/trialleft
    useEffect(() => {
        api.get('/usage/trialleft', { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                setTrial(response.data.message);
            })
            .catch(error => {
                console.error(error);
            });
    }, [token]);

    const cancelSubscription = async () => {
        api.post('/usage/cancel-subscription', {}, { headers: { Authorization: `Bearer ${token}` } })
            .then(response => {
                window.notify(response.data.message);
            })
            .catch(error => {
                console.error(error);
            });
    }

    return (
        <div className='container'>
            <link href="https://api.fontshare.com/v2/css?f[]=satoshi@900,500,400&display=swap" rel="stylesheet"></link>
            <Sidebar />
            <div className='main'>
                <h1 className='title'>Billing Settings</h1>
                <p className='description'> Manage your billing settings</p>
                <div className='setting-tab'>
                    <div className='setting-tab-title'>Current Plan :</div>
                    <div className='setting-tab-content'>
                        <div className='setting-tab-content-title'>{tier}</div>
                    </div>
                </div>
                {tier == 'Free Trial' &&
                <div className='setting-tab'>
                    <div className='setting-tab-title'>Trial :</div>
                    <div className='setting-tab-content'>
                        <div className='setting-tab-content-title'>{trial}</div>
                    </div>
                </div>
                }
                <div className='setting-tab actions'>
                    <div className='setting-tab-title'>Actions :</div>
                    <div className='setting-tab-content'>
                        <div className='setting-tab-content-title'>
                            <a href='/pricing' className='button'>Upgrade plan</a>
                            <div className='button' onClick={cancelSubscription}>Cancel subscription</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Billing;