import React from 'react';
import { useLocation } from 'react-router-dom';

const OnboardingSidebar = () => {
    const location = useLocation();

    const menuItems = [
        {path: "/onboarding?step=1",alt: "Welcome", name: "1. Welcome"},
        {path: "/onboarding?step=2",alt: "Settings", name: "2. AI Settings"},
        {path: "/onboarding?step=3",  alt: "Chrome Extension", name: "3. Chrome Extension"},
        {path: "/onboarding?step=4", alt: "First Block", name: "4. First Block"},
        {path: "/onboarding?step=5", alt: "To the Dashboard", name: "5. To the Dashboard"},
    ];

    return (
        <div className='sidebar onboarding'>
            <div className='menu onboarding'>
                {menuItems.map(item => (
                    <a key={item.path} href={item.path} className={location.pathname+location.search === item.path ? 'active' : ''}>
                        {item.name}
                    </a>
                ))}
            </div>
        </div>
    );
}

export default OnboardingSidebar;
